// src/contextApi/SocketContext.js

import React, { createContext, useEffect, useState, useContext, useRef } from 'react';
import { io } from 'socket.io-client';
import { AppContext } from './AppContext';

export const SocketContext = createContext({
  socket: null,
  isConnected: false,
  user: null, // Información del usuario
});

const SocketProvider = ({ children }) => {
  const { AppData } = useContext(AppContext);
  const [isConnected, setIsConnected] = useState(false);
  const socketRef = useRef(null);

  const socketUrl = process.env.NODE_ENV === 'development'
    ? "http://localhost:5002" // En desarrollo, conecta directamente al backend
    : "https://entregadirecto.com"; // En producción, usa la URL sin puerto

  useEffect(() => {
    const token = AppData.user?.token;
    const userId = AppData.user?._id;

    console.log("SocketProvider useEffect: Inicio del efecto.");
    console.log("SocketProvider: Token:", token);
    console.log("SocketProvider: UserID:", userId);

    if (token && !socketRef.current) {
      console.log("SocketProvider: Intentando conectar a Socket.io con token:", token);

      const newSocket = io(socketUrl, {
        path: '/socket.io/', // Debe coincidir con la configuración del backend y Nginx
        transports: ['websocket'], // Forzar el uso exclusivo de WebSocket
        auth: { token }, // Envía el token para autenticación
        reconnection: true,
        reconnectionAttempts: 10,
        reconnectionDelay: 2000,
        reconnectionDelayMax: 5000,
        autoConnect: true,
      });

      // Evento de conexión exitosa
      newSocket.on('connect', () => {
        console.log('SocketProvider: Conectado a Socket.io con ID:', newSocket.id);
        setIsConnected(true);
        console.log('SocketProvider: isConnected establecido a true');
      });

      // Evento de desconexión
      newSocket.on('disconnect', (reason) => {
        console.log('SocketProvider: Desconectado de Socket.io. Razón:', reason);
        setIsConnected(false);
        console.log('SocketProvider: isConnected establecido a false');
      });

      // Manejo de errores de conexión
      newSocket.on('connect_error', (err) => {
        console.error('SocketProvider: Error de conexión de Socket.io:', err.message);
      });

      // Manejo de otros errores en el socket
      newSocket.on('error', (err) => {
        console.error('SocketProvider: Error en Socket.io:', err.message);
      });

      // Manejo de intentos de reconexión
      newSocket.on('reconnect_attempt', (attempt) => {
        console.log(`SocketProvider: Intento de reconexión #${attempt}`);
      });

      // Manejo de reconexión fallida
      newSocket.on('reconnect_failed', () => {
        console.error('SocketProvider: Reconexión a Socket.io fallida.');
      });

      // Manejo de errores durante la reconexión
      newSocket.on('reconnect_error', (err) => {
        console.error('SocketProvider: Error durante la reconexión de Socket.io:', err.message);
      });

      socketRef.current = newSocket;
      console.log('SocketProvider: Nueva instancia de socket establecida');
    } else {
      console.log("SocketProvider: No hay token disponible o ya hay una conexión previa.");
    }

    // Limpieza al desmontar el componente o cuando cambia el token/socketUrl
    return () => {
      if (socketRef.current) {
        console.log("SocketProvider: Desconectando de Socket.io y limpiando eventos.");
        socketRef.current.off('connect');
        socketRef.current.off('disconnect');
        socketRef.current.off('connect_error');
        socketRef.current.off('error');
        socketRef.current.off('reconnect_attempt');
        socketRef.current.off('reconnect_failed');
        socketRef.current.off('reconnect_error');
        socketRef.current.disconnect();
        socketRef.current = null;
        setIsConnected(false);
        console.log("SocketProvider: Socket desconectado y estado limpiado");
      }
    };
  }, [AppData.user?.token, socketUrl]); // Asegúrate de que 'socketUrl' no cambia innecesariamente

  return (
    <SocketContext.Provider value={{ socket: socketRef.current, isConnected, user: AppData.user }}>
      {children}
    </SocketContext.Provider>
  );
};

export default SocketProvider;
