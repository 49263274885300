// Navbar.js
import React, { useContext, useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Container,
  Box,
  Grid,
  Dialog,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import CreateShipment from '../Modals/CreateShipment';
import Cookies from 'universal-cookie';
import { AppContext } from '../../contextApi/AppContext';
import Logo from "../../assets/imgaes/logotest.png";  // El logo que quieres usar
import { motion } from 'framer-motion';
import './Navbar.scss';

const cookies = new Cookies();

const Navbar = () => {
  const { dispatch } = useContext(AppContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [showCreateShipment, setShowCreateShipment] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [elevate, setElevate] = useState(false);

  // Función de Logout corregida
// Navbar.js
const handleLogout = () => {
  cookies.remove('user', { path: '/' });
  cookies.remove('token', { path: '/' });
  dispatch({ type: 'LOGOUT' }); // Tipo de acción unificado
  navigate('/login');
};


  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setElevate(true);
    } else {
      setElevate(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const LoginNav = () => <Typography variant="h6">Español</Typography>;

  const RegisterNav = () => (
    <Box className="nav-buttons">
      <Button
        variant="contained"
        color="primary"
        component={Link}
        to="/register"
        state={{ type: 'shipper' }}
      >
        Hago envíos
      </Button>
      <Button
        variant="outlined"
        color="primary"
        component={Link}
        to="/register"
        state={{ type: 'carrier' }}
      >
        Hago Entrega
      </Button>
    </Box>
  );

  const DefaultNav = () => (
    <Box className="nav-buttons">
      <Button variant="outlined" color="primary" component={Link} to="/login">
        Ingresar
      </Button>
      <Button
        variant="contained"
        color="primary"
        component={Link}
        to="/register"
        state={{ type: 'carrier' }}
      >
        Registrarse
      </Button>
    </Box>
  );

  const DashboardNav = () => {
    const user = cookies.get('user');
    return (
      <Box className="nav-buttons">
        {(user.isShipper || user.isAdmin) && (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setShowCreateShipment(true)}
            >
              Crear nuevo envío
            </Button>
            <Button component={Link} to="/dashboard">
              Envíos
            </Button>
          </>
        )}
        {!user.isShipper && (
          <>
            <Button component={Link} to="/dashboard">
              Envíos
            </Button>
            <Button component={Link} to="/shipments">
              Mis envíos
            </Button>
          </>
        )}
        <Button variant="outlined" color="secondary" onClick={handleLogout}>
          Cerrar Sesión
        </Button>
      </Box>
    );
  };

  const AdminNav = () => (
    <Box className="nav-buttons">
      <Button component={Link} to="/logistic">
        Rutas
      </Button>
      <Button component={Link} to="/dashboard">
        Envíos
      </Button>
      <Button variant="outlined" color="secondary" onClick={handleLogout}>
        Cerrar Sesión
      </Button>
    </Box>
  );

  const renderNavigation = () => {
    const user = cookies.get('user');
    if (location.pathname === '/login') return <LoginNav />;
    if (location.pathname === '/register') return <RegisterNav />;
    if (
      ['/dashboard', '/shipments', '/logistic'].includes(location.pathname)
    ) {
      return user?.isAdmin ? <AdminNav /> : <DashboardNav />;
    }
    return <DefaultNav />;
  };

  const DrawerMenu = () => {
    const user = cookies.get('user');
    return (
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List>
            {(user?.isShipper || user?.isAdmin) && (
              <ListItem button onClick={() => setShowCreateShipment(true)}>
                <ListItemText primary="Crear nuevo envío" />
              </ListItem>
            )}
            <ListItem button component={Link} to="/dashboard">
              <ListItemText primary="Dashboard" />
            </ListItem>
            {user?.isAdmin && (
              <ListItem button component={Link} to="/logistic">
                <ListItemText primary="Rutas" />
              </ListItem>
            )}
            <ListItem button onClick={handleLogout}>
              <ListItemText primary="Cerrar Sesión" />
            </ListItem>
          </List>
        </Box>
      </Drawer>
    );
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      className="Navbar"
    >
      <AppBar
        position="fixed"
        color="default"
        elevation={elevate ? 4 : 0}
        className={elevate ? 'Navbar_elevated' : ''}
      >
        <Dialog
          open={showCreateShipment}
          onClose={() => setShowCreateShipment(false)}
          maxWidth="sm"
          fullWidth
        >
          <CreateShipment Handlestatus={setShowCreateShipment} />
        </Dialog>
        <Container maxWidth="lg">
          <Toolbar disableGutters>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              wrap="nowrap"
            >
              <Grid item>
                <Link to="/">
                  <img
                    src={Logo}
                    alt="KaballeroDelivery Logo"
                    className="Navbar_logo"
                  />
                </Link>
              </Grid>
              <Grid item sx={{ display: { xs: 'none', md: 'block' } }}>
                {renderNavigation()}
              </Grid>
              <Grid item sx={{ display: { xs: 'block', md: 'none' } }}>
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={toggleDrawer(true)}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Toolbar>
        </Container>
        <DrawerMenu />
      </AppBar>
    </motion.div>
  );
};

export default Navbar;
