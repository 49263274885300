// src/contextApi/AppContext.js

import React, { createContext, useReducer, useEffect } from "react";
import AppReducers from "./AppReducers";
import InitState from "./InitState";
import Cookies from "universal-cookie";

export const AppContext = createContext();

const AppProvider = ({ children }) => {
  const cookies = new Cookies();
  const userCookie = cookies.get("user");
  const token = cookies.get("token");

  // Validamos si userCookie es una cadena y la parseamos a objeto si es necesario
  const initialState = {
    ...InitState,
    user: userCookie && typeof userCookie === "string" ? JSON.parse(userCookie) : userCookie,
  };

  const [AppData, dispatch] = useReducer(AppReducers, initialState);

  useEffect(() => {
    // Log para verificar el estado inicial al montar el provider
    console.log("AppProvider - Estado inicial:", AppData);

    // Si existe un token y no hay usuario en el estado, sincronizar el estado con las cookies
    if (token && !AppData.user) {
      try {
        const parsedUser = JSON.parse(userCookie);
        dispatch({ type: "LOGIN", payload: parsedUser });
        console.log("AppProvider - Usuario sincronizado desde cookies.");
      } catch (error) {
        console.error("AppProvider - Error al parsear userCookie:", error);
      }
    }
  }, [token, userCookie, AppData.user]);

  useEffect(() => {
    // Log para verificar el estado cada vez que se actualiza
    console.log("AppProvider - Estado actualizado:", AppData);
  }, [AppData]);

  return (
    <AppContext.Provider value={{ AppData, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
