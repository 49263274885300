// src/pages/Login/Login.js

import "./Login.scss";
import { Navbar } from "../../components";
import LoginImg from "../../assets/imgaes/login_image.png";
import { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { AppContext } from "../../contextApi/AppContext";
import axios from "../../Apis/axiosConfig"; // Importar axios configurado

const Login = () => {
  const {
    AppData: { isLoading },
    dispatch,
  } = useContext(AppContext);
  const cookies = new Cookies();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [showPass, setShowPass] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({ type: "LOADING", payload: false });
  }, [dispatch]);

  const handlePassword = () => {
    setShowPass((prevState) => !prevState);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleRedirect = (user) => {
    if (user.isAdmin) {
      navigate("/logistic");
    } else if (user.isBiker) {
      navigate("/biker");
    } else {
      navigate("/shipments");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch({ type: "LOADING", payload: true });

    try {
      const { email, password } = formData;
      if (!email || !password) {
        alert("Email y contraseña son obligatorios!");
        dispatch({ type: "LOADING", payload: false });
        return;
      }

      // Usar la instancia de Axios configurada para hacer la solicitud de login
      const response = await axios.post("/auth/login", formData);
      const { user } = response.data;

      console.log("Usuario autenticado:", user);

      // Guarda el token y el usuario en las cookies
      cookies.set("token", user.token, { path: "/" });
      cookies.set("user", JSON.stringify(user), { path: "/" });
      console.log("Cookies establecidas: ", cookies.getAll());

      // Actualiza el estado global con el usuario autenticado
      dispatch({ type: "LOGIN", payload: user });

      // Redirige basado en el rol del usuario
      handleRedirect(user);
    } catch (error) {
      console.error("Login - Error:", error);
      if (error.response && error.response.data && error.response.data.message) {
        alert(error.response.data.message);
      } else {
        alert("Ocurrió un error inesperado.");
      }
      dispatch({ type: "LOADING", payload: false });
    }
  };

  return (
    <section className="Login">
      <Navbar />
      <div className="Login_content">
        <div className="Login_content_left">
          <img src={LoginImg} alt="LoginImage" />
        </div>
        <div className="Login_content_right">
          <div className="Login_content_right_content">
            <h1 className="Login_content_right_content_title">Bienvenido de Nuevo</h1>
            <h3 className="Login_content_right_content_subtitle">
              Inicia sesión para continuar
            </h3>
            <form className="Login_content_right_content_form" onSubmit={handleSubmit}>
              <input
                type="email"
                name="email"
                placeholder="Dirección de email"
                className="inpt Login_content_right_content_form_input"
                onChange={handleChange}
                value={formData.email}
                required
              />
              <div className="Login_content_right_content_form_password">
                <input
                  type={showPass ? "text" : "password"}
                  name="password"
                  placeholder="Contraseña"
                  className="inpt Login_content_right_content_form_input"
                  onChange={handleChange}
                  value={formData.password}
                  minLength="6"
                  maxLength="12"
                  required
                />
                <span onClick={handlePassword} style={{ cursor: 'pointer', color: '#007BFF' }}>
                  {showPass ? "Ocultar" : "Mostrar"}
                </span>
              </div>
              <span className="Login_content_right_content_form_text">
                ¿Olvidaste tu contraseña?
              </span>
              <button className="btn-primary" disabled={isLoading}>
                {isLoading ? "Iniciando sesión..." : "Iniciar Sesión"}
              </button>
            </form>
            <div className="Login_content_right_content_underForm">
              <span>¿No tienes una cuenta?</span>
              <div className="Login_content_right_content_underForm_actions">
                <Link to="/register" state={{ type: "client" }}>
                  <button className="btn-outline">Crear cuenta de cliente</button>
                </Link>
                <Link to="/register" state={{ type: "biker" }}>
                  <button className="btn-outline">Crear cuenta de mensajero</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
