// src/utils/axiosConfig.js

import axios from "axios";
import Cookies from "universal-cookie";

const cookies = new Cookies();

// Configuración dinámica del baseURL
axios.defaults.baseURL =
  window.location.hostname === "localhost"
    ? "http://localhost:5002/api/v1" // Cambia a 5002 para que coincida con el backend en localhost
    : "https://entregadirecto.com/api"; // Dominio de producción con SSL

// Interceptor de solicitud: añade el token de autorización a las cabeceras
axios.interceptors.request.use(
  (config) => {
    const token = cookies.get("token");
    console.log("Interceptor de solicitud - Token actual:", token); // Log para depuración
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Interceptor de respuesta: maneja errores como el token expirado
axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Verificar si el error es por token expirado
    if (
      error.response &&
      error.response.status === 401 &&
      error.response.data.message === "jwt expired" &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      console.log("Interceptor de respuesta - Token expirado. Intentando renovar el token..."); // Log para depuración

      try {
        // Intentar renovar el token
        const { data } = await axios.post("/auth/refresh", {
          token: cookies.get("token"),
        });
        console.log("Interceptor de respuesta - Nuevo token recibido:", data.token); // Log para depuración

        // Guardar el nuevo token en las cookies
        cookies.set("token", data.token, { path: "/" });

        // Actualizar la cabecera de autorización en la solicitud original
        originalRequest.headers.Authorization = `Bearer ${data.token}`;

        // Reintentar la solicitud original con el nuevo token
        return axios(originalRequest);
      } catch (refreshError) {
        console.error("Interceptor de respuesta - Error al renovar el token:", refreshError); // Log para depuración

        // Si no se puede renovar el token, redirigir al login
        cookies.remove("token", { path: "/" });
        cookies.remove("user", { path: "/" });
        window.location.href = "/login";
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export default axios;
