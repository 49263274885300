// src/pages/Dashboard/Dashboard.js

import React, { useContext, useEffect, useState } from "react";
import { Navbar } from "../../components";
import "./Dashboard.scss";
import { allPercels, percelsShipper } from "../../Apis/parcel";
import { AppContext } from "../../contextApi/AppContext";
import CarrierTable from "../../pages/dashboard/components/CarrierTable"; // Importa el componente
import ShipperTable from "../../pages/dashboard/components/ShipperTable"; // Importa el componente

const Dashboard = () => {
  const { AppData, dispatch } = useContext(AppContext);
  const [shipments, setShipments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [totalPages, setTotalPages] = useState(1); // Asumiendo que tu API retorna el total de páginas
  const [currentPage, setCurrentPage] = useState(1);

  const fetchData = async (apiCall, page = 1, limit = 10) => {
    setIsLoading(true);
    setErrorMessage("");
    try {
      const response = await apiCall({}, page, limit);
      // Asumiendo que la respuesta tiene la estructura { data: { data: [...], totalPages: n } }
      const data = response.data.data;
      const total = response.data.totalPages || 1; // Ajusta según tu API
      setShipments(data);
      setTotalPages(total);
      setCurrentPage(page);
      dispatch({ type: "GET_PERCELS", payload: data });
      console.log("Dashboard - Datos de envíos obtenidos:", data);
    } catch (error) {
      console.error("Dashboard - Error al obtener envíos:", error);
      if (error.response && error.response.data && error.response.data.message) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage("Ocurrió un error inesperado.");
      }
      // Manejar error de carga
      dispatch({ type: "LOADING", payload: false });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (AppData.user) {
      dispatch({ type: "LOADING", payload: true });
      const apiCall = AppData.user.isShipper ? percelsShipper : allPercels;
      // Pasar filtros, página y límite según necesidad
      fetchData(apiCall, 1, 10);
    }
  }, [AppData.user, dispatch]);

  const handleApplyFilters = (filters) => {
    const apiCall = AppData.user.isShipper ? percelsShipper : allPercels;
    fetchData(apiCall, filters.page, 10); // Ajusta el límite si es necesario
  };

  return (
    <section className="Dashboard">
      <Navbar />
      <div className="container">
        <div className="Dashboard_content">
          {isLoading ? (
            <p>Cargando....</p>
          ) : errorMessage ? (
            <p className="Dashboard_content_error">{errorMessage}</p>
          ) : shipments && shipments.length ? (
            AppData.user.isShipper ? (
              <ShipperTable
                shipments={shipments}
                onApplyFilters={handleApplyFilters}
                totalPages={totalPages}
                currentPage={currentPage}
              />
            ) : (
              <CarrierTable shipments={shipments} />
            )
          ) : (
            <p className="Dashboard_content_empty">
              Haz clic en crear nueva entrega para comenzar
            </p>
          )}
        </div>
      </div>
    </section>
  );
};

export default Dashboard;
