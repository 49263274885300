// src/pages/dashboard/components/CarrierTable.js

import React, { useState } from 'react';
import AssignRoute from '../../../components/Modals/AssignRoute';
import ParcelDetailModal from '../../../components/Modals/ParcelDetail';

const CarrierTable = ({ shipments }) => {
  const [showAssignRoute, setShowAssignRoute] = useState(false);
  const [parcelID, setParcelID] = useState(null);
  const [selectedParcel, setSelectedParcel] = useState(null);
  const [activeTab, setActiveTab] = useState('unassigned');

  const [searchTerm, setSearchTerm] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [filterProvince, setFilterProvince] = useState("");

  const handleAssign = (parcelId) => {
    setParcelID(parcelId);
    setShowAssignRoute(true);
  };

  const handleParcelSelected = (parcel) => {
    setSelectedParcel(parcel);
  };

  const filterFunction = (parcel) => {
    let isMatch = true;

    if (activeTab === 'unassigned' && parcel.routeId !== null) isMatch = false;
    if (activeTab === 'intransit' && parcel.status !== 'intransit') isMatch = false;

    if (searchTerm) {
      const terms = searchTerm.toLowerCase().split(' ');
      if (
        !terms.some(
          (term) =>
            parcel.name.toLowerCase().includes(term) ||
            parcel.companyName.toLowerCase().includes(term)
        )
      ) {
        isMatch = false;
      }
    }

    if (filterStatus && parcel.status !== filterStatus) isMatch = false;
    if (filterProvince && parcel.province !== filterProvince) isMatch = false;

    return isMatch;
  };

  const filteredParcels = (shipments || []).filter(filterFunction);

  return (
    <>
      {showAssignRoute && (
        <AssignRoute
          parcelID={parcelID}
          handleStatus={() => setShowAssignRoute(false)}
        />
      )}
      {selectedParcel && (
        <ParcelDetailModal
          selectedParcel={selectedParcel}
          closeModal={() => setSelectedParcel(null)}
        />
      )}

      <div className="tab-menu">
        <button
          className={activeTab === 'unassigned' ? 'active' : ''}
          onClick={() => setActiveTab('unassigned')}
        >
          Sin Ruta
        </button>
        <button
          className={activeTab === 'intransit' ? 'active' : ''}
          onClick={() => setActiveTab('intransit')}
        >
          En Ruta
        </button>
        <button
          className={activeTab === 'all' ? 'active' : ''}
          onClick={() => setActiveTab('all')}
        >
          Todos
        </button>
      </div>

      <div className="search-and-filter-bar">
        <input
          type="text"
          placeholder="Buscar..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <select
          onChange={(e) => setFilterStatus(e.target.value)}
          value={filterStatus}
        >
          <option value="">Filtrar por estado</option>
          <option value="delivered">Entregado</option>
          <option value="waiting">Esperando</option>
          {/* Añade más opciones si es necesario */}
        </select>
        <select
          onChange={(e) => setFilterProvince(e.target.value)}
          value={filterProvince}
        >
          <option value="">Filtrar por provincia</option>
          <option value="Heredia">Heredia</option>
          <option value="Alajuela">Alajuela</option>
          <option value="San Jose">San José</option>
          <option value="Cartago">Cartago</option>
          {/* Añade más provincias si es necesario */}
        </select>
      </div>

      <table className="Dashboard_content_table">
        <thead>
          <tr>
            <th>Detalles</th>
            <th>Estado</th>
            <th>Ruta</th>
            <th>Información</th>
          </tr>
        </thead>
        <tbody>
          {filteredParcels.map((parcel, index) => (
            <tr key={parcel._id || index}>
              <td>
                <div className="Dashboard_content_table_request-details">
                  <p>
                    <span>Pedido: </span>
                    {parcel.name}
                  </p>
                  <p>
                    <span>Empresa: </span>
                    {parcel.companyName}
                  </p>
                  <p>
                    <span>Dirección de Recolección: </span>
                    {parcel.pickup_address}
                  </p>
                  <p>
                    <span>Dirección de Entrega: </span>
                    {parcel.dropoff_address}
                  </p>
                  {parcel.routeId !== null && (
                    <p>
                      <span>ID de Ruta: </span>
                      {parcel.routeId}
                    </p>
                  )}
                </div>
              </td>

              <td
                className={`Dashboard_content_table_request-status Dashboard_content_table_request-status_${parcel.status}`}
              >
                <span>{parcel.status}</span>
              </td>
              <td>
                <button
                  className="Dashboard_content_table_btn"
                  onClick={() => handleAssign(parcel._id)}
                >
                  Asignar
                </button>
              </td>
              <td>
                <button onClick={() => handleParcelSelected(parcel)}>
                  Ver detalles
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default CarrierTable;
