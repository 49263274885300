// Hero.js
import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import './Hero.scss';
import BackgroundImage from '../../assets/imgaes/hero.jpg';

const Hero = () => {
  return (
    <div className="Hero">
      <motion.div
        className="Hero_background"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        style={{ backgroundImage: `url(${BackgroundImage})` }}
      ></motion.div>
      <div className="Hero_content">
        <motion.h1
          className="Hero_content_title"
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8, ease: 'easeOut' }}
        >
          Nos encargamos de la logística de tus envíos
        </motion.h1>
        <motion.p
          className="Hero_content_desc"
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8, ease: 'easeOut', delay: 0.3 }}
        >
          Somos un servicio premium para tu empresa o emprendimiento
        </motion.p>
        <motion.div
          className="Hero_content_actions"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.6 }}
        >
          <Link
            to="/register"
            state={{ type: 'client' }}
            className="btn-primary"
          >
            <b>Quiero realizar envíos</b>
            <span>Registrarse ahora</span>
          </Link>

        </motion.div>
      </div>
      <motion.div
        className="scroll-indicator"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, y: [0, 10, 0] }}
        transition={{ duration: 2, repeat: Infinity }}
      >
        <span></span>
      </motion.div>
    </div>
  );
};

export default Hero;
