// src/pages/adminDashboard/AdminDashboard.js

import React, { useState, useContext, useEffect } from "react";
import {
  AppBar, Toolbar, IconButton, Drawer, List, ListItem, ListItemIcon, ListItemText, Typography, useMediaQuery, Box
} from "@mui/material";
import { Menu as MenuIcon, ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon } from '@mui/icons-material';
import { FaShoppingCart, FaRoute, FaTruck, FaUsers, FaBuilding, FaMoneyBill, FaClipboardList, FaCogs } from "react-icons/fa";
import { styled, useTheme } from '@mui/material/styles';
import Cookies from "universal-cookie";
import { AppContext } from "../../contextApi/AppContext";
import Pedidos from './Components/pedidos';
import Rutas from './Components/RouteManagement';
import Mensajeros from './Components/mensajeros';
import Organizaciones from './Components/organizaciones';
import Desembolsos from './Components/desembolsos';
import Usuarios from './Components/usuarios';
import Estadisticas from './Components/estadisticas';
import Configuraciones from './Components/configuraciones';
import ManageWarehousePickups from '../../components/ManageWarehousePickups';
import { SocketContext } from "../../contextApi/SocketContext";
import { toast } from 'react-toastify';
import { Badge } from '@mui/material';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: open ? drawerWidth : 0,
  width: open ? `calc(100% - ${drawerWidth}px)` : '100%',
  [theme.breakpoints.down('sm')]: {
    marginLeft: 0,
    width: '100%',
    padding: theme.spacing(2),
  },
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  minHeight: 56,
  justifyContent: 'flex-end',
}));

function AdminDashboard() {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [activeComponent, setActiveComponent] = useState(null);
  const { AppData } = useContext(AppContext);
  const cookies = new Cookies();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { socket, isConnected, user } = useContext(SocketContext); // Incluye socket y user

  // Estados para notificaciones
  const [newRouteCreated, setNewRouteCreated] = useState(false);
  const [newRouteUpdated, setNewRouteUpdated] = useState(false);
  const [newRouteDeleted, setNewRouteDeleted] = useState(false);
  const [newParcelsAssigned, setNewParcelsAssigned] = useState(false);
  const [newParcelAssigned, setNewParcelAssigned] = useState(false);

  useEffect(() => {
    if (!isConnected || !socket) {
      console.warn("Socket no está disponible o no está conectado en AdminDashboard.");
      return;
    }

    // Función para enviar notificaciones
    const sendNotification = (title, options) => {
      if (Notification.permission === "granted") {
        new Notification(title, options);
      }
    };

    // Solicitar permiso de notificación al montar el componente
    if (Notification.permission !== "granted") {
      Notification.requestPermission().then(permission => {
        if (permission === "granted") {
          console.log("Permiso de notificación concedido.");
        } else {
          console.log("Permiso de notificación denegado.");
        }
      });
    }

    // Escucha general de eventos para depuración
    const handleAnyEvent = (event, ...args) => {
      console.log(`Socket recibió evento: '${event}'`, args);
      // Puedes agregar más lógica aquí si lo deseas
    };

    // Registra la escucha general
    socket.onAny(handleAnyEvent);
    console.log("AdminDashboard: Escucha general de eventos registrada.");

    // Define los eventos específicos que el componente escuchará
    const handleSocketEvents = [
      { 
        event: "admin:routeCreated", 
        handler: (data) => { 
          console.log("Evento 'admin:routeCreated' recibido.", data);
          toast.info("Nueva ruta creada."); 
          sendNotification("Nueva ruta creada", {
            body: "Se ha creado una nueva ruta.",
            icon: "/icono-notificacion.png", // Reemplaza con la ruta a tu icono
          });
          setNewRouteCreated(true); 
        } 
      },
      { 
        event: "admin:routeUpdated", 
        handler: (data) => { 
          console.log("Evento 'admin:routeUpdated' recibido.", data);
          toast.info("Ruta actualizada."); 
          sendNotification("Ruta actualizada", {
            body: "Se ha actualizado una ruta existente.",
            icon: "/icono-notificacion.png",
          });
          setNewRouteUpdated(true); 
        } 
      },
      { 
        event: "admin:routeDeleted", 
        handler: (data) => { 
          console.log("Evento 'admin:routeDeleted' recibido.", data);
          toast.warn("Ruta eliminada."); 
          sendNotification("Ruta eliminada", {
            body: "Se ha eliminado una ruta.",
            icon: "/icono-notificacion.png",
          });
          setNewRouteDeleted(true); 
        } 
      },
      { 
        event: "admin:parcelsAssigned", 
        handler: (data) => { 
          console.log("Evento 'admin:parcelsAssigned' recibido.", data);
          toast.info("Paquetes asignados a una ruta."); 
          sendNotification("Paquetes asignados", {
            body: "Se han asignado paquetes a una ruta.",
            icon: "/icono-notificacion.png",
          });
          setNewParcelsAssigned(true); 
        } 
      },
      { 
        event: "admin:parcelAssigned", 
        handler: (data) => { 
          console.log("Evento 'admin:parcelAssigned' recibido.", data);
          toast.info("Paquete asignado a una ruta."); 
          sendNotification("Paquete asignado", {
            body: "Se ha asignado un paquete a una ruta.",
            icon: "/icono-notificacion.png",
          });
          setNewParcelAssigned(true); 
        } 
      },
      // Puedes agregar más eventos aquí siguiendo el mismo patrón
    ];

    console.log("AdminDashboard: Registrando eventos específicos de Socket.io");

    // Registra cada evento con su respectivo manejador
    handleSocketEvents.forEach(({ event, handler }) => {
      socket.on(event, handler);
      console.log(`AdminDashboard: Evento '${event}' registrado.`);
    });

    // Limpia los eventos al desmontar el componente
    return () => {
      console.log("AdminDashboard: Desregistrando eventos específicos de Socket.io");
      handleSocketEvents.forEach(({ event, handler }) => {
        socket.off(event, handler);
        console.log(`AdminDashboard: Evento '${event}' desregistrado.`);
      });
      // Limpia la escucha general
      socket.offAny(handleAnyEvent);
      console.log("AdminDashboard: Escucha general de eventos desregistrada.");
    };
  }, [socket, isConnected]);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleMenuItemClick = (Component) => {
    setActiveComponent(<Component />);
    if (isSmallScreen) {
      setOpen(false);  // Cierra el drawer automáticamente en pantallas pequeñas
    }
  };

  const menuItems = [
    { label: 'Gestión de Pedidos', Icon: FaShoppingCart, Component: Pedidos },
    { label: 'Gestión de Rutas', Icon: FaRoute, Component: Rutas },
    { label: 'Gestión de Usuarios Internos', Icon: FaTruck, Component: Mensajeros },
    { label: 'Gestión de Organizaciones', Icon: FaBuilding, Component: Organizaciones },
    { label: 'Gestión de Desembolsos', Icon: FaMoneyBill, Component: Desembolsos },
   /* { label: 'Gestión de Usuarios', Icon: FaUsers, Component: Usuarios },
    /* { label: 'Reportes y Estadísticas', Icon: FaClipboardList, Component: Estadisticas },*/
    { label: 'Gestión de Recolecciones', Icon: FaClipboardList, Component: ManageWarehousePickups },
    { label: 'Configuraciones del Sistema', Icon: FaCogs, Component: Configuraciones },

  ];

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position="fixed" sx={{ zIndex: theme.zIndex.drawer + 1 }}>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <IconButton color="inherit" edge="start" onClick={toggleDrawer} sx={{ mr: 2 }}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            Dashboard de Administradores
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant={isSmallScreen ? 'temporary' : 'persistent'}
        anchor="left"
        open={open}
        onClose={toggleDrawer}
        sx={{ 
          width: drawerWidth, 
          flexShrink: 0,
          '& .MuiDrawer-paper': { width: drawerWidth, boxSizing: 'border-box' } 
        }}
      >
        <DrawerHeader>
          <IconButton onClick={toggleDrawer}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <List>
          {menuItems.map(({ label, Icon, Component }) => (
            <ListItem button key={label} onClick={() => handleMenuItemClick(Component)}>
              <ListItemIcon sx={{ minWidth: 40 }}>
                <Icon style={{ fontSize: isSmallScreen ? '1.2rem' : '1.5rem' }} />
              </ListItemIcon>
              <ListItemText primary={label} sx={{ fontSize: isSmallScreen ? '0.8rem' : '1rem' }} />
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Main open={!isSmallScreen || open}>
        <DrawerHeader />
        {activeComponent}
      </Main>

      {/* Notificaciones y Badges (Opcional) */}
      {/* Puedes agregar un componente para mostrar un resumen de notificaciones */}
    </Box>
  );
}

export default AdminDashboard;
